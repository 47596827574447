import React, { SVGAttributes } from 'react'

export const Key: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" color="var(--blue-default-color)" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7071 6.70711C18.0976 6.31658 18.0976 5.68342 17.7071 5.29289C17.3166 4.90237 16.6834 4.90237 16.2929 5.29289L11.7574 9.8284C10.967 9.30488 10.0191 9 9 9C6.23858 9 4 11.2386 4 14C4 16.7614 6.23858 19 9 19C11.7614 19 14 16.7614 14 14C14 12.9809 13.6951 12.033 13.1716 11.2426L15.5 8.91421L17.2929 10.7071C17.6834 11.0976 18.3166 11.0976 18.7071 10.7071C19.0976 10.3166 19.0976 9.68342 18.7071 9.29289L16.9142 7.5L17.7071 6.70711ZM6 14C6 12.3431 7.34315 11 9 11C10.6569 11 12 12.3431 12 14C12 15.6569 10.6569 17 9 17C7.34315 17 6 15.6569 6 14Z"
    />
  </svg>

)
