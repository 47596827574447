import React, { SVGAttributes } from 'react'

export const ArrowWithStickDown: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" color="var(--blue-default-color)" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.29297 14.7071L6.70718 13.2929L11.0001 17.5858L11.0001 5C11.0001 4.44772 11.4478 4 12.0001 4C12.5524 4 13.0001 4.44772 13.0001 5L13.0001 17.5858L17.293 13.2929L18.7072 14.7071L12.0001 21.4142L5.29297 14.7071Z"
    />
  </svg>
)
