import React, { SVGAttributes } from 'react'

export const List: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="var(--blue-default-color)" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 8C4.44772 8 4 8.44772 4 9C4 9.55228 4.44772 10 5 10H19C19.5523 10 20 9.55228 20 9C20 8.44772 19.5523 8 19 8H5ZM5 14C4.44772 14 4 14.4477 4 15C4 15.5523 4.44772 16 5 16H12C12.5523 16 13 15.5523 13 15C13 14.4477 12.5523 14 12 14H5Z"
    />
  </svg>
)
