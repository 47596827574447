import React, { SVGAttributes } from 'react'

export const PieChart: React.FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    color="var(--blue-default-color)"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.30664 16.3847C5.64501 16.9016 6.04378 17.3802 6.49667 17.8094C7.61886 18.873 9.02523 19.5887 10.5456 19.8698C12.066 20.1509 13.6353 19.9853 15.0636 19.3932C16.4919 18.8011 17.718 17.8078 18.5936 16.5335C19.4692 15.2591 19.9567 13.7583 19.9972 12.2127C20.0378 10.6671 19.6295 9.14282 18.8219 7.82435C18.0143 6.50588 16.8419 5.4497 15.4466 4.78358C14.5023 4.33277 13.4832 4.07369 12.4479 4.01562L13.0031 6.08752C13.5482 6.17996 14.0808 6.34777 14.5849 6.58845C15.6314 7.08804 16.5107 7.88018 17.1164 8.86903C17.7221 9.85789 18.0283 11.0011 17.9979 12.1603C17.9675 13.3195 17.6019 14.4451 16.9452 15.4009C16.2885 16.3566 15.3689 17.1016 14.2977 17.5457C13.2265 17.9898 12.0495 18.1139 10.9092 17.9031C9.76892 17.6923 8.71415 17.1555 7.8725 16.3578C7.69677 16.1913 7.53189 16.0148 7.37854 15.8296L5.30664 16.3847Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.71913 3.62251C7.93338 3.93012 7.19322 4.34732 6.52115 4.86301C5.58349 5.58251 4.79672 6.47964 4.20577 7.50319C3.61482 8.52675 3.23127 9.65667 3.077 10.8285C2.96642 11.6683 2.9752 12.5179 3.10168 13.3522L10.7753 11.2961L8.71913 3.62251ZM10.8741 11.7036C10.8741 11.7036 10.8741 11.703 10.874 11.702L10.8741 11.7036ZM11.1761 11.1787C11.1771 11.1782 11.1776 11.1779 11.1776 11.178L11.1761 11.1787ZM8.75262 1.50577C9.0252 1.44889 9.37786 1.43664 9.74303 1.62379C10.1221 1.81808 10.3156 2.1289 10.4237 2.37812C10.5098 2.57669 10.5738 2.81609 10.6259 3.01115C10.6295 3.02467 10.6331 3.03798 10.6366 3.05104L12.7071 10.7784C12.7105 10.7909 12.7139 10.8036 12.7173 10.8165C12.7692 11.0095 12.8309 11.2393 12.8573 11.4441C12.8892 11.6914 12.8917 12.0445 12.6867 12.3996C12.4816 12.7548 12.1745 12.9291 11.9445 13.0252C11.7539 13.1047 11.524 13.1662 11.3309 13.2178C11.3181 13.2212 11.3054 13.2246 11.2929 13.2279L3.56549 15.2985C3.55242 15.302 3.53912 15.3056 3.5256 15.3092C3.33063 15.3616 3.09133 15.4259 2.8763 15.4506C2.60643 15.4816 2.24049 15.4695 1.88269 15.2383C1.53803 15.0156 1.37231 14.7041 1.28527 14.4396C1.21856 14.2369 1.18003 13.9993 1.15009 13.8147C1.14791 13.8013 1.14577 13.7881 1.14367 13.7752C0.970788 12.7161 0.953515 11.6353 1.09411 10.5674C1.28266 9.13522 1.75145 7.7542 2.47372 6.50319C3.19599 5.25219 4.15759 4.15569 5.30362 3.27631C6.15816 2.6206 7.10276 2.09518 8.10646 1.71532C8.11865 1.71071 8.13113 1.70597 8.14389 1.70113C8.3187 1.63477 8.54369 1.54936 8.75262 1.50577Z"
    />
  </svg>
)
